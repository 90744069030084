<template>
  <v-app>
    <v-app-bar app>
      <v-btn color="#20419A" icon>
        <v-icon>mdi-menu</v-icon>
      </v-btn>
      <v-spacer />
      <div>
        <base-item
          :item="{
            title: $t('salir'),
            icon: 'mdi-arrow-up-bold-box-outline',
          }"
          color="dark"
        />
      </div>
    </v-app-bar>
    <v-main>
      <span class="bg"></span>
      <!-- <v-img :src="require('@/assets/header.jpg')"></v-img> -->
      <div class="inner">
        <v-card class="mx-auto px-10" width="900" elevation="10">
          <v-card-title class="font-weight-light">
            <div class="welcome">
              <h1 class="mx-auto text-center">Bienvenido</h1>
            </div>
            <h2
              class="mx-auto text-center"
            >{{cuetaNoRegistrada.nombre + ' ' + cuetaNoRegistrada.apellidoPaterno + ' ' + cuetaNoRegistrada.apellidoMaterno}}</h2>
            <div class="welcome__text">
              <h3>
                <span>{{ currentTitle }}</span>
              </h3>
            </div>
          </v-card-title>
          <!-- <v-avatar color="primary lighten-2" class="subheading white--text" size="24" v-text="step"></v-avatar> -->
          <v-window v-model="step">
            <!-- Mostrar datos cuenta  -->
            <v-window-item :value="1">
              <base-material-card color="drawerColorSecondary" :avatar="cuenta.image">
                <template v-slot:heading>
                  <div class="display-2 font-weight-light">Datos de la Cuenta</div>
                </template>
                <v-row>
                  <v-col cols="8" md="4">
                    <v-text-field
                      v-model="this.cuetaNoRegistrada.nombre"
                      label="Nombre(s)"
                      disabled
                    />
                  </v-col>
                  <v-col cols="4" md="4">
                    <v-text-field
                      v-model="this.cuetaNoRegistrada.apellidoPaterno"
                      label="Apellido Paterno"
                      disabled
                    />
                  </v-col>
                  <v-col cols="6" md="4">
                    <v-text-field
                      v-model="this.cuetaNoRegistrada.apellidoMaterno"
                      label="Apellido Materno"
                      disabled
                    />
                  </v-col>
                  <v-col cols="6" md="2">
                    <v-text-field
                      v-model="this.cuetaNoRegistrada.matricula"
                      label="Matricula"
                      disabled
                    />
                  </v-col>
                  <v-col cols="12" md="6">
                    <v-text-field v-model="this.cuetaNoRegistrada.correo" label="Correo" disabled />
                  </v-col>
                  <v-col cols="12" md="4">
                    <v-text-field v-model="programaEducativoActivo" label="Carrera" disabled />
                  </v-col>
                </v-row>
              </base-material-card>
            </v-window-item>

            <!-- Seleccionar el tutor -->
            <v-window-item :value="2">
              <base-material-card
                color="drawerColorSecondary"
                class="px-auto"
                :avatar="cuenta.image"
              >
                <v-combobox
                  v-model="tutor"
                  :items="this.tutoresAPI"
                  item-text="correo"
                  label="Tutores"
                  return-object
                  auto-select-first
                />
              </base-material-card>
            </v-window-item>

            <!-- Aceptar  -->
            <v-window-item :value="3">
              <base-material-card color="drawerColorSecondary" :avatar="cuenta.image">
                <div class="welcome">
                  <h2>Su cuenta fue configurada correctamente.</h2>
                  <p>Al dar Aceptar se regresara a la pagina principal y ya podra Iniciar Sesion</p>
                  <v-btn color="verdeBoton" @click="aceptar()">Aceptar</v-btn>
                </div>
              </base-material-card>
            </v-window-item>
            <v-divider />
            <!-- Acciones -->
            <v-card-actions>
              <v-btn :disabled="step === 1" text @click="step--">Back</v-btn>
              <v-spacer></v-spacer>
              <v-btn :disabled="step === 3" color="verdeBoton" depressed @click="step++">Next</v-btn>
            </v-card-actions>
          </v-window>
        </v-card>
        <!-- <div class="inner">
        <div class="inner__welcome">
          <h1>Bienvenido {{cuetaNoRegistrada.nombre + ' ' + cuetaNoRegistrada.apellidoPaterno + ' ' + cuetaNoRegistrada.apellidoMaterno}}</h1>
          <p>Este es el primer paso para accerder al portal, en el portal podras consultar información referente a tu cuenta y a tus prestamos.</p>
          <p>Te pedimos que verifiques tus datos para poder acceder al nuevo Sistema de Prestamos.</p>
          <p>Si alguno de tus datos estan mal, te pedimos porfavor que canceles el proceso y te dirijas con un Responsable de caseta.</p>
        </div>-->
        <!-- </div> -->
      </div>
    </v-main>
  </v-app>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "VerficiarCuenta",
  data() {
    return {
      cuetaNoRegistrada: {},
      tutor: "",
      tutoresAPI: [],
      programaEducativoActivo: "",
      step: 1,
    };
  },
  computed: {
    ...mapState(["cuenta"]),
    currentTitle() {
      switch (this.step) {
        case 1:
          return "Datos de la Cuenta";
        case 2:
          return "Seleccione su Tutor";
        default:
          return "Aceptar";
      }
    },
  },
  mounted() {
    //Primero obtnemos la cuenta
    this.cuetaNoRegistrada = this.cuenta;

    //Buscamos su programa educativo activo
    this.cuetaNoRegistrada.programasEducativos.forEach((element) => {
      if (element.estado == "Activo") {
        this.programaEducativoActivo = element.programaEducativo;
        return;
      }
    });

    //Buscacos los tutores en la base de datos
    fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/tutores", {
      method: "GET",
      headers: {
        Authorization: "Bearer " + this.cuenta.token,
      },
    })
      .then((response) => {
        return response.json();
      })
      .then((tutores) => {
        this.tutor = tutores[0];
        this.tutoresAPI = tutores;
      });
  },
  methods: {
    aceptar() {
      fetch(process.env.VUE_APP_URL_BACKEND + "/administrador/cuentas", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + this.cuenta.token,
        },
        body: JSON.stringify({
          idCuenta: this.cuetaNoRegistrada.matricula,
          nombre: this.cuetaNoRegistrada.nombre,
          apellidoMaterno: this.cuetaNoRegistrada.apellidoMaterno,
          apellidoPaterno: this.cuetaNoRegistrada.apellidoPaterno,
          correo: this.cuetaNoRegistrada.correo,
          idTutor: this.tutor.idTutor,
          carrera: this.programaEducativoActivo,
          tipo: "Alumno",
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((cuenta) => {
          this.logout();
        });
    },
    logout: function () {
      this.$store.dispatch("logout");
      this.$router.push("/SPML/login");
      const w = window.open('https://accounts.google.com/Logout');        
    },
  },
};
</script>

<style>
.bg {
    width: 100%;
    height: 95%;
    position: absolute;
    top: 0;
    left: 0;
    background: url('../../../assets/header.jpg') no-repeat center center;
    background-size: cover;
    background-color: red;
    transform: scale(1.1);
  }
.welcome {
  width: 100%;
  padding-top: 4px;
  padding-bottom: 4px;
  text-align: center;
  position: relative;
  font-size: 1.5em;
}

@media screen and (min-width: 601px) {
  .welcome__text {
    width: 100%;
    margin-bottom: 35px;
    align-content: center;
    text-align: center;
    font-size: 20px;
  }
}

@media screen and (max-width: 600px) {
  .welcome__text {
    width: 100%;
    text-align: center;
    margin-bottom: 35px;
  }
}

.colorText {
  color: #20419a;
}
</style>